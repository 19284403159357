import react from "react";
import { Container, Row, Col } from "react-bootstrap";
import MemeCard from "./MemeCard";


/**
 * Takes in memes, spits out cards
 * @param {object} memes 
 */
function renderCardsFromMemes(memes) {
    //write function that renders out meme cards here
}

/**
 * A general Meme Section. Pass the list of memes that you want to render into this component, and it will spit out meme cards for each meme.
 * @param {object} props
 */
export default function MemeSection(props) {

  return (
    <Container fluid>
      <Row>
        <Col className="d-flex flex-wrap">
          
          {/* //loop through to render Memes */}
           {props.memes && props.memes.map(meme => <MemeCard key={meme.id} meme={meme} />)}
        </Col>
      </Row>
    </Container>
  );
}
