import {Container, Row, Col} from 'react-bootstrap'
import MemeSection from './MemeSection'
import { auth, firestore } from '../firebaseStuff.js';
import { useState, useEffect } from 'react'

//get top memes from API
function useMemes() {
    const [memes, setMemes] = useState([]);

    useEffect(() => {
        firestore.collection('memes').orderBy('score', 'desc').limit(25).onSnapshot((snapshot) => {
            const newMemes = snapshot.docs.map((doc) => ({
                id: doc.id,
                ... doc.data()
            }));
            setMemes(newMemes);
        })
       
    }, []);
    return memes;
}

/**
 * The "Top Memes" Section on the Main Page
 * @param {object} props 
 */
export default function TopMemes(props) {

    const memes = useMemes();

    return <Container fluid className='m-3'>
        <Row>
            <Col className='d-flex flex-row'>
            <h2>Top Memes</h2>
            </Col>
        </Row>
        <Row>
            <Col className='text-left'>
            <p>The highest rated memes of all time!</p>
            </Col>
        </Row>
        <Row>
            <Col className='pl-0'>
            <MemeSection memes={memes}></MemeSection>
            </Col>
        </Row>
    </Container>
}