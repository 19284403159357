import { useEffect, useState } from "react";
import { Card, Image, Modal, Button } from "react-bootstrap";
import Keyword from "./Keyword";
import { auth, firestore } from '../firebaseStuff.js';

// function getCorg() {
//   fetch("https://dog.ceo/api/breed/pembroke/images/random")
//     .then((res) => res.json())
//     .then((res) => {
//       console.log(res.message);
//       return res.message;
//     });
// }

/**
 * This is the meme card, which should typically be rendered within a MemeSection. You will need to pass information about the meme to this function, including and especially the url for the photo
 * @param {props} props
 */
export default function MemeCard(props) {
  const [memeLink, updateLink] = useState("");
  const [show, setShow] = useState(false);

  const { memeName, bio, subject: subject, color, displayName, photoURL, uid, keywords, score, downvoteUsers, upvoteUsers } = props.meme;
  const key = props.meme.id;

  const deleteMeme = () => {
    firestore.collection('memes').doc(key).delete().then(
      console.log("deleted!")
    ).catch(error => console.log(error));
  }

  const upvoteMeme = () => {
    let memeRef = firestore.collection('memes').doc(key);

    //find the current meme
    const meme = memeRef.get().then(doc => {
      //console.log(doc.data());
      let upvoteUsers = doc.data().upvoteUsers
      let score = doc.data().score
      let updatedUpvoteUsers;
      let newScore;

      //only submit if not upvoted already
      if (!upvoteUsers || !upvoteUsers.includes(auth.currentUser.uid)) {

        if (upvoteUsers) {
          updatedUpvoteUsers = upvoteUsers;
          updatedUpvoteUsers.push(auth.currentUser.uid);
        }
        else {
          updatedUpvoteUsers = []
          updatedUpvoteUsers.push(auth.currentUser.uid);
        }
        if (score) {
          newScore = ++score;
        }
        else {
          newScore = 1;
        }
        memeRef.update({
          score: newScore,
          upvoteUsers: updatedUpvoteUsers
        })
      }
      //user already upvoted, undo
      else {
        updatedUpvoteUsers = upvoteUsers;
        updatedUpvoteUsers.pop(auth.currentUser.uid);
        newScore = --score;
        memeRef.update({
          score: newScore,
          upvoteUsers: updatedUpvoteUsers
        })
      }
    })
  }

  const downvoteMeme = () => {
    let memeRef = firestore.collection('memes').doc(key);

    //find the current doggo
    const meme = memeRef.get().then(doc => {
      let downvoteUsers = doc.data().downvoteUsers
      let score = doc.data().score
      let updatedDownvoteUsers;
      let newScore;

      //only submit if not downvoted already
      if (!downvoteUsers || !downvoteUsers.includes(auth.currentUser.uid)) {
        if (downvoteUsers) {
          updatedDownvoteUsers = downvoteUsers;
          updatedDownvoteUsers.push(auth.currentUser.uid);
        }
        else {
          updatedDownvoteUsers = []
          updatedDownvoteUsers.push(auth.currentUser.uid);
        }
        if (score) {
          newScore = --score;
        }
        else {
          newScore = -1;
        }
        memeRef.update({
          score: newScore,
          downvoteUsers: updatedDownvoteUsers
        })
      }
      //user already downvoted, undo
      else {
        updatedDownvoteUsers = downvoteUsers;
        updatedDownvoteUsers.pop(auth.currentUser.uid);
        newScore = ++score;
        memeRef.update({
          score: newScore,
          downvoteUsers: updatedDownvoteUsers
        })
      }
    })

  }

  //TODO: edit meme from clicking on the card
  const editMeme = () => {

  }

  return (
    <div className="mt-3 mr-3 shadow memeCardParent">
      <Image
        variant="top"
        className="memeCard"
        src={photoURL}
        onClick={() => {
          setShow(true);
        }}
      ></Image>
      <div className="memeVoteBox"><img className="memeUpvote" style={{opacity: [upvoteUsers.includes(auth.currentUser.uid) ? "60%" : "100%"]}} onClick={() => upvoteMeme()} src='https://image.flaticon.com/icons/png/512/2492/2492366.png' />
        <img className="memeDownvote" style={{opacity: [downvoteUsers.includes(auth.currentUser.uid) ? "60%" : "100%"]}} onClick={() => downvoteMeme()} src='https://image.flaticon.com/icons/png/512/2492/2492366.png' />
        <div className="memeScore">{score ? score : 0}</div>
      </div>
      <p className="memeCardName">{memeName}</p>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        width="100%"
        size="xl"
        centered
      >
        <div className="d-flex flex-row flex-wrap" style={{ minHeight: '50vh' }}>
          <Image src={photoURL} style={{ flexGrow: 1, objectFit: 'cover', maxHeight: '70vh' }} />
          <div className="m-3 grow" style={{ width: "400px" }}>
            <div className='d-flex flex-column justify-content-between' style={{ height: '100%' }}>
              <div>
                <h3>{memeName}</h3>
                <small className="text-muted">
                  <p>{subject} created by {displayName}</p>
                </small>
                <p>
                  {bio}
                </p>
              </div>
              <div>
                {(uid == auth.currentUser.uid) ? <Button className='memeButton my-1' onClick={deleteMeme}>Delete Meme</Button> : <></>}
                {(uid == auth.currentUser.uid) ? <Button className='memeButton my-1' onClick={editMeme}>Edit Meme</Button> : <></>}
                <div className="d-flex flex-row mt-auto" id="keywords">
                  {keywords && keywords.map((keyword, i) => <Keyword key={i} keyword={keyword} />)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
