import React from 'react';
import { auth, firestore } from './firebaseStuff.js';
import firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useState } from 'react';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import MemeCard from './Home/MemeCard.js';
import MemeSection from './Home/MemeSection.js';
import MyMemes from './Home/MyMemes.js';
import TopMemes from './Home/TopMemes.js';

export default function Home(props) {

    const memesRef = firestore.collection('memes');
    const query = memesRef.orderBy('createdAt').limit(25);

    const [memes] = useCollectionData(query, { idField: 'id' });

    const [formMemeName, setMemeName] = useState('');
    const [formBreed, setBreed] = useState('');
    const [formColor, setColor] = useState('');
    const [formBirthday, setBirthday] = useState(new Date());

    const sendMessage = async (e) => {
        e.preventDefault();
        const { uid, photoURL, displayName } = auth.currentUser;

        if (formMemeName == '' || formMemeName.trim() == '') {
            alert("Please enter in a full name for the meme.")
            return;
        }

        await memesRef.add({
            memeName: formMemeName,
            breed: formBreed,
            color: formColor,
            birthday: formBirthday,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            uid,
            displayName,
            photoURL
        })
        setMemeName('');
        setBreed('');
        setColor('');
        setBirthday(new Date());
    }

        return (<div>
            <Container fluid style={{marginTop: '66px', backgroundColor: '#ebebeb'}}>
        <Row>
            <Col>
            <MyMemes />
            <TopMemes />
            </Col>
            
            
            </Row>
    </Container>
            </div>);
    
}



//memes
    //name
    //id
    //breed
    //color
    //birthday
    //images of the meme
        //user
        //time
        //image URL
        //keywords
        //score
        //upvoteUsers
        //downVoteUsers

//users
    //name
    //id
    //meme(s)