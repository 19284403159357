import { useState } from "react"
import { Modal, Button, Form, Col } from "react-bootstrap"
import firebase from 'firebase'
const { firestore, auth } = require('../firebaseStuff')



const memesRef = firestore.collection('memes');
const memeStorage = firebase.storage().ref()

function addMeme(name, bio, photo, subject, setValidated, keywords, callback) {
    const form = document.getElementById('form')
    setValidated(true)
    if (form.checkValidity() === false) {
        return;
    }
    console.log('here')
    const { uid, photoURL, displayName } = auth.currentUser;

    let re = /\s|,\s/; // split on space or comma space
    keywords = keywords.split(re);

    var newImageRef = memeStorage.child('images/' + name)
    newImageRef.put(photo)
    .then( function(snapshot) {
        console.log('uploaded a file!')
        snapshot.ref.getDownloadURL()
        .then(function(url) {
            memesRef.add({
                memeName: name,
                bio: bio,
                subject: subject,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid,
                displayName,
                photoURL: url,
                keywords: keywords,
                score: 0,
                upvoteUsers: [],
                downvoteUsers: []
            })
            setValidated(false)
            callback()
        })
    })
    .catch(err => {
        alert('Something went wrong')
        console.log(err)
    })
}

export default function AddMeme(props) {
    const [show, setShow] = useState(false)
    const [validated, setValidated] = useState(false);
    return (<>
    <Button className='memeButton my-1' onClick={e => setShow(true)}>Add</Button>
    <Modal show={show} onHide={e => setShow(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Add Meme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form noValidate validated={validated} id='form'>
                <Form.Group controlId='name'>
                    <Form.Label>Meme Name</Form.Label>
                    <Form.Control required></Form.Control>
                    <Form.Control.Feedback type="invalid">
              Give your meme a name!
            </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='bio'>
                    <Form.Label>Biography</Form.Label>
                    <Form.Control as="textarea" rows="3" required></Form.Control>
                    <Form.Control.Feedback type="invalid">
              Please tell us a little bit about your meme.
            </Form.Control.Feedback>
                </Form.Group>
                <Form.Row>
                <Form.Group as={Col} controlId='subject'>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control as='select' required defaultValue=''>
                        <option value=''></option>
                        <option>Calculus</option>
                        <option>Biology</option>
                        <option>Psychology</option>
                        <option>Music Theory</option>
                        <option>Microeconomics</option>
                        <option>Macroeconomics</option>
                        <option>Other</option>      
                    </Form.Control>
                </Form.Group>
                </Form.Row>
                <Form.Group controlId='photo'>
                    <Form.Label>Photo</Form.Label>
                    <Form.File required style={{overflow: 'hidden'}} accept='image/*'>
                    
                    </Form.File>
                </Form.Group>
                <Form.Group controlId='keywords'>
                        <Form.Label>Keywords</Form.Label>
                        <Form.Control required></Form.Control>
                    </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={e => addMeme(document.getElementById('name').value, document.getElementById('bio').value, document.getElementById('photo').files[0], document.getElementById('subject').value, setValidated, document.getElementById('keywords').value, () => {
                //console.log('success!')
                setShow(false);
            })}>Add Meme</Button>
        </Modal.Footer>
    </Modal>
    </>)
}