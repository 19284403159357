import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

firebase.initializeApp({
  apiKey: "AIzaSyB1vPTUruRRX29giPYaDurrQSyk82isrCo",
  authDomain: "academeme-6c156.firebaseapp.com",
  projectId: "academeme-6c156",
  storageBucket: "academeme-6c156.appspot.com",
  messagingSenderId: "611283656080",
  appId: "1:611283656080:web:04ef45b3ee556ec7be2715",
  measurementId: "G-40CNEHK8VX"
  });
  
  const auth = firebase.auth();
  const firestore = firebase.firestore();

  export {auth, firestore}