import { useState, useEffect } from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import AddMeme from './AddMeme'
import MemeSection from './MemeSection'
import { auth, firestore } from '../firebaseStuff.js';
import firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';

//get my memes from API
function useMemes() {
    const [memes, setMemes] = useState([]);

    useEffect(() => {
        firestore.collection('memes').where("uid", "==", auth.currentUser.uid).onSnapshot((snapshot) => {
            const newMemes = snapshot.docs.sort().map((doc) => ({
                id: doc.id,
                ... doc.data()
            }));
            setMemes(newMemes);
        })
    }, []);
    return memes;
}

/**
 * The "My Memes" section on the main page
 * @param {object} props 
 */
export default function MyMemes(props) {

const memes = useMemes();

    return <Container fluid className='m-3'>
        <Row>
            <Col className='d-flex flex-row'>
                <h2 className='mr-3'>My Memes</h2>
                <AddMeme />
            </Col>
        </Row>
        <Row>
            <Col className='text-left'>
                <p>Upload and manage your memes here!</p>
            </Col>
        </Row>
        <Row>
            <Col className='pl-0'>
                <MemeSection memes={memes}></MemeSection >
            </Col>
        </Row>
    </Container>
}